import { useEffect, useState } from "react";

export const useScrollPosition = () => {
  const [isScrolling, setIsScrolling] = useState(0);
  useEffect(() => {
    const updatePosition = () => {
      setIsScrolling(window.pageYOffset);
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  return isScrolling;
};


