import React from 'react'
import Topbar from './Topbar/Topbar'
import MobileTopbar from './MobileTopbar/MobileTopbar'

import { useScrollPosition } from '../../utils/customHooks'

const Header = () => {
    const scrollPosition = useScrollPosition();

  return (
    <>
    <Topbar scrolled={scrollPosition} />
    <MobileTopbar scrolled={scrollPosition} />
    </>
  )
}

export default Header