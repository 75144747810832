import React from 'react'
import "./loader.css"

const Loader = () => {
  return (
    <div className='app__loader_container'>
    <div className='lds-dual-ring'/>
    </div>
  )
}

export default Loader